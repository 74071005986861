import * as React from "react"
import { Menu, X } from "react-feather"
import {
  Container,
  Flex,
  FlexList,
  Space,
  NavLink,
  Button,
  InteractiveIcon,
  Nudge,
  VisuallyHidden,
} from "./ui"
import {
  mobileNavOverlay,
  mobileNavLink,
  desktopHeaderNavWrapper,
  mobileHeaderNavWrapper,
  mobileNavSVGColorWrapper,
} from "./header.css"
import NavItemGroup from "./nav-item-group"
import Logo from "./logo"

export default function Header() {
  const [isOpen, setOpen] = React.useState(false)

  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = "visible"
    }
  }, [isOpen])

  return (
    <header>
      <Container className={desktopHeaderNavWrapper}>
        <Space size={2} />
        <Flex variant="spaceBetween">
          <NavLink to="/">
            <VisuallyHidden>Home</VisuallyHidden>
            <Logo />
          </NavLink>
          <nav>
            <FlexList gap={4}>
              {/* <li>
                <NavItemGroup
                  name="Portfolio"
                  navItems={[
                    {
                      text: "Lawy",
                      description: "test",
                      href: "/portfolio/lawy",
                    },
                  ]}
                />
              </li> */}
              {/* <li>
                <NavLink to="/careers">Careers</NavLink>
              </li> */}
              {/* <li>
                <NavLink to="/research">Research</NavLink>
              </li> */}
            </FlexList>
          </nav>
          <nav>
            <FlexList gap={4}>
              <NavLink to="/careers">Careers</NavLink>
              <NavLink to="/insiders">Become an Insider</NavLink>
              <NavLink to="https://usepanorama.com/" target="_blank">
                Panorama
              </NavLink>
              <Button to="/contact">Learn more</Button>
            </FlexList>
          </nav>
        </Flex>
      </Container>
      <Container className={mobileHeaderNavWrapper[isOpen ? "open" : "closed"]}>
        <Space size={2} />
        <Flex variant="spaceBetween">
          <span
            className={
              mobileNavSVGColorWrapper[isOpen ? "reversed" : "primary"]
            }
          >
            <NavLink to="/">
              <VisuallyHidden>Home</VisuallyHidden>
              <Logo />
            </NavLink>
          </span>
          <Flex>
            <Space />

            <div>
              <Button to="/contact" variant={isOpen ? "reversed" : "primary"}>
                Learn more
              </Button>
            </div>

            <Nudge right={3}>
              <InteractiveIcon
                title="Toggle menu"
                onClick={() => setOpen(!isOpen)}
                className={
                  mobileNavSVGColorWrapper[isOpen ? "reversed" : "primary"]
                }
              >
                {isOpen ? <X /> : <Menu />}
              </InteractiveIcon>
            </Nudge>
          </Flex>
        </Flex>
      </Container>
      {isOpen && (
        <div className={mobileNavOverlay}>
          {/*<nav>
             <FlexList responsive variant="stretch">
              <li>
                <NavItemGroup name="Portfolio" navItems={[]} />
              </li>
              <li>
                <NavLink to="/careers">Careers</NavLink>
              </li>
              <li>
                <NavLink to="/research">Research</NavLink>
              </li>
              ))}
            </FlexList>
          </nav>*/}
          <nav>
            <FlexList responsive variant="stretch">
              <li>
                <NavLink to="/careers" style={{ color: "white" }}>
                  Careers
                </NavLink>
              </li>
              <li>
                <NavLink to="/insiders" style={{ color: "white" }}>
                  Become an insider
                </NavLink>
              </li>
              <li>
                <NavLink to="/gouvernance" style={{ color: "white" }}>
                  Programme « Vers une saine gouvernance »
                </NavLink>
              </li>
            </FlexList>
          </nav>
        </div>
      )}
    </header>
  )
}
