import * as React from "react"
import Header from "./header"
import Footer from "./footer"
import Head from "./head"
import "../styles.css"

export default function Layout(props) {
  return (
    <div
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      <Head {...props} />
      <Header />
      <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        {props.children}
      </div>
      <Footer />
    </div>
  )
}
