import * as React from "react"
import {
  Twitter,
  Linkedin,
  GitHub,
} from "react-feather"
import {
  Container,
  Flex,
  FlexList,
  Box,
  Space,
  //NavLink,
  Text,
  IconLink,
  VisuallyHidden,
} from "./ui"

export default function Footer() {
  return (
    <Box as="footer" paddingY={4}>
      <Container>
        {/* <Flex variant="start" responsive>
          <Space />
            <FlexList variant="start" responsive>
              <li>
                <NavLink to="https://vaults.lawy.co/">
                  <Text variant="small">Lawy.co</Text>
                </NavLink>
              </li>

              <li>
                <NavLink to="https://app.hoopback.com/">
                  <Text variant="small">Hoopback.com</Text>
                </NavLink>
              </li>
            </FlexList>
        </Flex>
        <Space size={5} /> */}
        <Flex variant="start" re                                          sponsive>
          <FlexList>
            <li>
              <IconLink to="https://github.com/iwnstudio">
                <VisuallyHidden>GitHub</VisuallyHidden>
                <GitHub />
              </IconLink>
            </li>
            <li>
              <IconLink to="https://www.linkedin.com/company/iwnstudio">
                <VisuallyHidden>Linkedin</VisuallyHidden>
                <Linkedin />
              </IconLink>
            </li>
          </FlexList>
          <Space />
          <FlexList>
            {/*
              <li>
                <NavLink to="/privacy">
                  <Text variant="small">Privacy Policy</Text>
                </NavLink>
              </li>

              <li>
                <NavLink to="/terms">
                  <Text variant="small">Terms & Condition</Text>
                </NavLink>
              </li>
            */}
          </FlexList>
          <Text variant="small">
            &copy; 2022 IWN, Inc. All rights reserved.
          </Text>
        </Flex>
      </Container>
      <Space size={3} />
    </Box>
  )
}
