import * as React from 'react'
import { Helmet } from 'react-helmet'
import banner from '../banner.png';

export default function Head ({
  title = 'Building software companies — iwn studio',
  description = 'At iwn studio, we build sustainable software companies that address the unique needs of small-businesses.',
  imageUrl = banner,
}) {

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en-us',
      }}>
      <meta charSet='utf-8' />
      <title>{title}</title>
      <meta name='description' property='og:description' content={description} />
      <meta property='og:title' content={title} />
      <meta property='og:image' content={imageUrl} />
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:title' content={title} />
      <meta name="twitter:site" content="@iwnstudio" />
      <meta name="twitter:creator" content="@iwnstudio" />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={imageUrl} />
    </Helmet>
  )
}
